<template>
  <div class="course-item" @click="toCourse(itemData.id)">
    <div class="img-wrap">
      <img class="img" :src="itemData.thumb" />
    </div>
    <div class="title">{{itemData.title}}</div>
    <div class="z-flex z-flex-between">
      <div class="teacher" >课时：{{itemData.videos_count}}节</div>
      <div class="classHour">订阅：{{itemData.user_count}}</div>
    </div>
    <div class="bottom-row z-flex z-flex-between">
      <div class="price-row z-flex" v-if="itemData.charge>0">
        <div class="price-now" v-if="itemData.charge">￥<span>{{itemData.charge}}</span></div>
        <div class="price-ori" v-if="false">￥{{itemData.priceOri}}</div>
      </div>
      <el-button type="success" size="mini" class="btn-view" v-if="itemData.charge==0">免费观看</el-button>
      <el-button type="primary" size="mini" class="btn-view" v-else>{{itemData.vip_free}}</el-button>
    </div>
  </div>
</template>

<script>
export default {
  name: "courseItem",
  props: {
    itemData:{
      type:[Array, Object],
      default:{},
    },
  },
  data() {
    return {
      
    };
  },
  methods: {
   toCourse(id){
      this.$router.push({
        path:'/course/'+id
      })
    },
  },
};
</script>

<style lang="scss" scoped>
.course-item{
  width:285px;
  background-color:#fff;
  cursor: pointer;
  &:hover{
    .img{
      transform:scale(1.1);
    }
  }
  .img-wrap{
    width:100%;
    height:220px;
    overflow:hidden;
    .img{
      width:100%;
      height:100%;
      object-fit: cover;
      transition: all .5s;
    }
  }
  .title{
    padding:10px 10px 5px;
    color: #666;
    font-weight: 700;
    @extend %textOverflow;
  }
  .teacher{
    width: 180px;
    @extend %textOverflow;
    font-size: 14px;
    color: #999;
    padding-left: 10px;
  }
  .classHour{
    font-size: 14px;
    color: #999;
    padding-right: 10px;
  }
  .bottom-row{
    padding: 10px 10px 10px 6px;
    height:35px;
    .price-row{
      color: #999;
      .price-now{
        margin-right: 6px;
        span{
          font-size: 26px;
          color: red;
          font-weight: 700;
        }
      }
      .price-ori{
        text-decoration: line-through;
        margin-bottom: -3px;
      }
    }
    .btn-view{
      padding: 7px;
    }
  }
}
</style>
