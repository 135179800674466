<template>
  <div class="recommend">
    <div class="hd z-flex z-flex-between">
      <span>相关推荐</span>
      <a href="javascript:void(0)" @click="next" class="btn-re z-flex">
        <i class="el-icon-refresh"></i>
        <span>换一批</span>
      </a>
    </div>
    <div class="bd">
      <courseItem
        v-for="(item,index) in rowList"
        :key="index"
        :itemData = item
      >
      </courseItem>
    </div>
  </div>
</template>

<script>
import courseItem from "../components/courseItem"
export default {
  name: "recommend",
  components:{
    courseItem
  },
  props: {
    
  },
  data() {
    return {
      currentPage: 1, //当前页数
      pageSize: 6, //每页20条
      rowTotal: 0,  //总数
      rowList: [], //列表
    };
  },
  mounted() {
    this.getRows();
  },
  methods: {
    next(){
      let pageMax = Math.ceil(this.rowTotal / this.pageSize);
      this.currentPage++;
      if (this.currentPage > pageMax) {
        this.currentPage = 1;
      }
      this.getRows();
    },
    getRows(){
      let params = {
        page: this.currentPage,
        page_size: this.pageSize
      };
      var that = this;
      this.$axios.api.recommend(params, function (res) {
        if (res.code == 0) {
          that.rowTotal = res.data.total;
          that.rowList = res.data.data;
        } else {
          console.log(res.msg);
        }
      });
    }
  },
};
</script>

<style lang="scss" scoped>
.recommend{
  width:285px;
  .hd{
    height:16px;
    line-height: 16px;
    margin-bottom: 15px;
    border-left: 3px solid $colorMain;
    padding-left: 6px;
    .btn-re {
      font-size: 14px;
      color: $colorMain;
      i{
        margin-right: 3px;
        font-size: 16px;
      }
    }
  }
}
.course-item{
  margin-bottom: 10px;
}
</style>
